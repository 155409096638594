@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #c7d5e0;
  color: #171a21;
}

/* Dark Mode */
body.dark-mode {
  background-color: #171a21;
  color: #c7d5e0;
}

.Custom-Dialog {
  background-color: #c7d5e0;
  color: #171a21;
}

.Custom-Dialog.dark-mode {
  background-color: #171a21;
  color: #c7d5e0;
}

/* Custom styles for dark mode */
.dark-mode .custom-textfield .MuiInputBase-input {
  color: #c7d5e0;
}

.dark-mode .custom-textfield .MuiInputLabel-root {
  color: #c7d5e0;
}

.dark-mode .custom-textfield .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #c7d5e0;
}

.dark-mode .custom-textfield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: cyan;
}

.dark-mode .custom-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: cyan;
}

.dark-mode .card-content {
  color: #c7d5e0;
}

.image-container {
  position: relative;
  overflow: hidden;
  /* Ensures the light effect does not overflow the image */
}

/* .image-container img {
  display: block; 
} */

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  /* Start the light effect off-screen to the left */
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  transition: left 0.5s ease;
  /* Smooth transition for moving the light effect */
  pointer-events: none;
  /* Ensure the pseudo-element does not block interactions */
}

.image-container:hover::before {
  left: 100%;
  /* Move the light effect across the image to the right on hover */
}



.recaptcha-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
}