/* Base carousel styles */
.carousel {
    cursor: grab;
    overflow: hidden;
    width: 100%;
    position: relative;
  }
  
  .inner-carousel {
    display: flex;
    gap: 1rem;
    padding: 1rem;
  }
  
  /* Card styles */
  .item {
    min-height: 1rem;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-shrink: 0;
  }
  
  /* Responsive breakpoints */
  @media screen and (max-width: 768px) {
    .inner-carousel {
      gap: 0.5rem;
      padding: 0.5rem;
    }
    
    section[class*='flex lg:min-w-[45%]'] {
      min-width: 90% !important;
      margin: 0.5rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    section[class*='flex lg:min-w-[45%]'] {
      min-width: 95% !important;
      margin: 0.25rem;
    }
    
    article.p-8 {
      padding: 1rem;
    }
  }
  
  /* Print styles */
  @media print {
    .print_btn {
      display: none;
    }
  }